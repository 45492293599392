import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { Container } from "@mui/system";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

import HeroImage from "../components/HeroImage";
import SectionDivider from "../components/SectionDivider";
import supabase from "../supabaseClient";

import HomeImage from "../asset/images/home.jpg";
import Grandpas from "../asset/images/grandpas.jpg";
import BigFam2022 from "../asset/images/bigFam2022.jpg"
import { PRIMARY_COLOR } from "../constant";

const Home = () => {
  const [families, setFamilies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const imageData = [
    HomeImage,
    BigFam2022
  ];

  useEffect(() => {
    const fetchFamilies = async () => {
      const { data, error } = await supabase
        .from("family")
        .select("*")
        .order("familyId", { ascending: true });

      if (error) {
        console.error("Error fetching families:", error);
        setError(error.message);
      } else {
        setFamilies(data || []); // Default to an empty array if no data
      }
      setLoading(false);
    };

    fetchFamilies();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Map familyId to tabIndex
  const mapFamilyIdToTabIndex = (familyId) => {
    const mapping = { 2: 1, 8: 1, 3: 2, 4: 3, 5: 4, 6: 5, 7:6, 9: 7};
    return mapping[familyId] || 0; // Default tabIndex to 0
  };

  // Component: FamilyCard
  const FamilyCard = ({ family }) => {
    const isVertical = family.familyId === 2 || family.familyId === 8;
    const imageSrc = isVertical
      ? family.familyImageVertical
      : family.familyImageHorizontal;

    return (
      <Grid
        item
        xs={12}
        md={6}
        key={family.familyId}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Card
          component={Link}
          to={`/family/${family.familyId}?tabIndex=${mapFamilyIdToTabIndex(
            family.familyId
          )}`}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
            textDecoration: "none",
            "&:hover": { boxShadow: 6 },
          }}
        >
          <CardMedia
            component="img"
            src={imageSrc}
            alt={family.familyDescription || "Family image"}
            loading="lazy"
            sx={{
              height: isVertical ? "447px" : "270px",
              width: isVertical ? "335px" : "425px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              {family.familyHusband}
            </Typography>
            {family.familyId !== 2 && family.familyId !== 8 && (
          <>
            <Typography
                      variant="h6"
                      sx={{ fontSize: 24, margin: "0 8px", color: "red" }}
                    >
                      &#10084; {/* Heart symbol */}
                    </Typography>
             <Typography variant="h6" gutterBottom>
                {family.familyWife}
              </Typography>

          </>
             
            )}
            <Typography variant="body1" color="textSecondary">
              {family.familyDescription}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <Box>
      {/* Top Section: Large Image */}
      <HeroImage images={imageData} />
      <SectionDivider text="Ông bà kính yêu" />

      {/* Second Section */}
      <Container sx={{ py: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            textAlign: "center",
            border: "1px solid #ccc",
            borderRadius: "8px",
            borderColor: PRIMARY_COLOR,
            p: 3,
            gap: 2,
          }}
        >
          {/* Grandma Info */}
          <Box
            component={Link}
            to={`/family/1?tabIndex=0`}
            sx={{
              flex: 1,
              textAlign: "center",
              p: { xs: 0, md: 2 },
              textDecoration: "none",
              color: "inherit",
              "&:hover": { cursor: "pointer", color: "primary.main" },
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Maria Vũ Thị Ngũ
            </Typography>
            <Typography variant="body1">
              Người mẹ hiền lành, tảo tần lo cho các con - người chị em chăm sóc
              tận tâm
            </Typography>
          </Box>

          {/* Image */}
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <img
              src={Grandpas}
              alt="Ông bà"
              loading="lazy"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </Box>

          {/* Grandpa Info */}
          <Box
            component={Link}
            to={`/family/1?tabIndex=0`}
            sx={{
              flex: 1,
              textAlign: "center",
              p: { xs: 0, md: 2 },
              textDecoration: "none",
              color: "inherit",
              "&:hover": { cursor: "pointer", color: "primary.main" },
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Giuse Nguyễn Văn Tài
            </Typography>
            <Typography variant="body1">
              Người ông thương cháu - người cha nghiêm khắc nhưng đầy tình
              thương
            </Typography>
          </Box>
        </Box>
      </Container>

      <Divider
        sx={{
          width: "50%",
          mx: "auto",
          borderColor: PRIMARY_COLOR,
          borderWidth: "4px",
          mb: 4,
        }}
      />

      {/* Section Below Divider */}
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: PRIMARY_COLOR }}
        >
          Hoa trái tình yêu
        </Typography>
      </Box>

      {/* Families Grid */}
      <Container sx={{ py: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          {/* Featured Families */}
          {families
            .filter((family) => [2, 8].includes(family.familyId))
            .map((family) => (
              <FamilyCard key={family.familyId} family={family} />
            ))}

          {/* Remaining Families */}
          {families
            .filter((family) => ![2, 8, 1, 10].includes(family.familyId))
            .map((family) => (
              <FamilyCard key={family.familyId} family={family} />
            ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Fade,
  Alert,
  Drawer,
  FormHelperText, // Import Drawer
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close"; // Import Close Icon
import { Link } from "react-router-dom";
import { PRIMARY_COLOR } from "../constant";
import supabase from "../supabaseClient";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

const NavbarWithModal = () => {
  const [openModal, setOpenModal] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // Mobile menu state
  const [storyName, setStoryName] = useState("");
  const [storyTeller, setStoryTeller] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [storyContent, setStoryContent] = useState("");
  const [categories, setCategories] = useState([]); // Dynamic categories
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errors, setErrors] = useState({}); // To track validation errors

  const menuItems = [
    { name: "Gia đình", path: "/families" },
    { name: "Chuyện nhà tôi", path: "/stories" },
    { name: "Kỉ niệm", path: "/libraries" },
  ];

  // Fetch categories when the modal opens
  const handleModalOpen = async () => {
    setOpenModal(true);
    try {
      const { data, error } = await supabase
        .from("category") // Categories table
        .select("categoryId, categoryName")
        .order("categoryId", { ascending: true });
      if (error) throw error;
      setCategories(data || []); // Populate categories in dropdown
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setCategoryId(""); // Reset the selected category when closing the modal
    setStoryName("");
    setStoryTeller("");
    setStoryContent("");
    setErrors({}); // Reset validation errors
    setSuccessMessage(false); // Reset success message
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Validate required fields
    const newErrors = {};
    if (!storyName) newErrors.storyName = "Tên câu chuyện trống nè";
    if (!storyTeller) newErrors.storyTeller = "Người kể trống nè";
    if (!categoryId) newErrors.categoryId = "Chọn một danh mục";
    if (!storyContent) newErrors.storyContent = "Nội dung câu chuyện trống nè";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase.from("story").insert([
        {
          storyName,
          storyTeller,
          categoryId,
          storyContent,
        },
      ]);

      console.log(data)

      if (error) {
        console.error("Insert Error:", error); // Log detailed error
        throw error;
      }

      // Show success message
      setSuccessMessage(true);

      // Clear form fields after submission
      setStoryName("");
      setStoryTeller("");
      setCategoryId("");
      setStoryContent("");
    } catch (error) {
      console.error("Error submitting story:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
      {/* Navbar */}
      <AppBar position="sticky" sx={{ backgroundColor: PRIMARY_COLOR }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { xs: "flex", md: "none" } }}
            onClick={toggleMobileMenu} // Toggle mobile menu visibility
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{
              flexGrow: 1,
              textAlign: { xs: "center", md: "left" },
              textDecoration: "none",
              color: "inherit",
              fontWeight: 900,
              fontFamily: "serif",
              fontSize: "1.5rem",
            }}
          >
            Đại Gia Đình OBCT
          </Typography>
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
            {menuItems.map((item) => (
              <Button
                key={item.name}
                color="inherit"
                component={Link}
                to={item.path}
                sx={{
                  textTransform: "none",
                  fontSize: "1.5rem",
                  fontWeight: 900,
                  fontFamily: "serif",
                }}
              >
                {item.name}
              </Button>
            ))}
            <Button
              variant="contained"
              onClick={handleModalOpen}
              sx={{
                textTransform: "none",
                fontSize: "1.2rem",
                backgroundColor: "#fff",
                color: PRIMARY_COLOR,
                fontWeight: 700,
                borderRadius: "20px",
                padding: "8px 16px",
                border: `2px solid ${PRIMARY_COLOR}`,
              }}
            >
              Kể chuyện
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile Menu Drawer */}
      <Drawer
        anchor="top"
        open={mobileMenuOpen}
        onClose={toggleMobileMenu}
        variant="temporary"
        PaperProps={{
          sx: {
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
            backgroundColor: "white",
            position: "relative",
          },
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={toggleMobileMenu}
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
            color: PRIMARY_COLOR,
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Menu Items */}
        {menuItems.map((item) => (
          <Button
            key={item.name}
            component={Link}
            to={item.path}
            onClick={toggleMobileMenu}
            sx={{
              textTransform: "none",
              fontSize: "2rem",
              marginBottom: "1.5rem",
              width: "80%",
              textAlign: "center",
              color: PRIMARY_COLOR,
            }}
          >
            {item.name}
          </Button>
        ))}

        {/* CTA in Mobile Menu */}
        <Button
          variant="contained"
          onClick={() => {
            toggleMobileMenu();
            handleModalOpen();
          }}
          sx={{
            textTransform: "none",
            fontSize: "2rem",
            backgroundColor: "#fff",
            color: PRIMARY_COLOR,
            fontWeight: 700,
            borderRadius: "20px",
            padding: "12px 24px",
            border: `2px solid ${PRIMARY_COLOR}`,
            marginTop: "16px",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            },
          }}
        >
          Kể chuyện
        </Button>
      </Drawer>

      {/* Modal */}
      <Modal open={openModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            p: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Close Icon */}
          <IconButton
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: PRIMARY_COLOR,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h6"
            sx={{ textAlign: "center", fontWeight: 700 }}
          >
            Những câu chuyện chưa kể...
          </Typography>
          <Typography
            variant="p"
            sx={{ m: 2, textAlign: "center", fontStyle: "italic" }}
          >
            Hãy thả lỏng cơ thể và thư giãn đầu óc, nhớ lại những ký ức và câu
            chuyện về người thân, rồi viết xuống bên dưới nhé.
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: "flex", flexDirection: "column", mt: 2 }}
          >
            <TextField
              label="Chuyện tên là ..."
              variant="outlined"
              fullWidth
              value={storyName}
              onChange={(e) => setStoryName(e.target.value)}
              sx={{ mb: 2 }}
              required
              InputLabelProps={{ required: false }}
              error={!!errors.storyName}
              helperText={errors.storyName}
            />
            <TextField
              label="Người kể là..."
              variant="outlined"
              fullWidth
              value={storyTeller}
              onChange={(e) => setStoryTeller(e.target.value)}
              sx={{ mb: 2 }}
              required
              InputLabelProps={{ required: false }}
              error={!!errors.storyTeller}
              helperText={errors.storyTeller}
            />
            <FormControl
              fullWidth
              sx={{ mb: 2 }}
              required
              error={!!errors.categoryId}
            >
              <InputLabel
                id="story-category-label"
                sx={{
                  backgroundColor: "white", // Set white background to avoid overlapping with Select
                  paddingLeft: "4px", // Padding to avoid overlap with the select box
                  paddingRight: "4px", // Same padding for consistency
                }}
                shrink={categoryId !== ""}
              >
                Chuyện nói về...
              </InputLabel>
              <Select
                labelId="story-category-label"
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
                sx={{
                  "& .MuiSelect-select": {
                    paddingTop: "12px", // Adjust padding for consistency
                    paddingBottom: "12px", // Keep padding consistent with the label
                  },
                }}
              >
                {categories.map((category) => (
                  <MenuItem key={category.categoryId} value={category.categoryId}>
                    {category.categoryName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.categoryId}</FormHelperText>
            </FormControl>
            <Editor
              apiKey="clpbjdubk5ryj807gz0v9htan0p8ie5bn8578b3tkc5jmgvz" // Optional if using the cloud version
              value={storyContent}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "charmap", // List your used plugins here
                ],
                toolbar:
                  "fontsize | undo redo | formatselect | bold italic | bullist numlist | outdent indent | link image",
                fontsize_formats:
                  "12pt 14pt 18pt 24pt 36pt 40pt 44pt 48pt 52pt",
                placeholder: "chuyện kể rằng...",
              }}
              onEditorChange={(content) => setStoryContent(content)}
              sx={{ mb: 2 }}
            />
            {errors.storyContent && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errors.storyContent}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" sx={{ mt: 2 }}>
                Chúc mừng bạn đã gửi câu chuyện thành công!
              </Alert>
            )}
           <Button
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{
                backgroundColor: PRIMARY_COLOR,
                display: "flex",
                alignItems: "center",
                fontFamily: "Arial, sans-serif", // Set your desired font family
                fontSize: "16px", // Change font size
                mt: 5,
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>
                  Bấm để lưu chuyện nha
                  <ArrowCircleRightOutlinedIcon sx={{ ml: 1 }} />{" "}
                </>
              )}
            </Button>
          </Box>
          {successMessage && (
            <Fade in={successMessage}>
              <Alert severity="success" sx={{ mt: 2 }}>
                Yay! Đã lưu chuyện kể thành công.{" "}
                <Link
                  to="/stories"
                  style={{ textDecoration: "underline", color: "green" }}
                >
                  Xem câu chuyện vừa lưu
                </Link>
              </Alert>
            </Fade>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default NavbarWithModal;

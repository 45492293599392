import React from 'react'

const demo = () => {
  

  return (
    <div>

      <img src="https://drive.google.com/thumbnail?id=14AGvhXCj9g3KBlkaFMUbkpbelGUlhvz2&sz=w1000" alt='test'/>

    </div>
  )
}

export default demo
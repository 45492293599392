import React from "react";
import { Box } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

const HeroImage = ({ images = [] }) => {
  const customArrowStyle = {
    position: "absolute",
    top: "50%",
    zIndex: 2,
    width: "50px",
    height: "50px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    border: "none",
    borderRadius: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const renderArrowPrev = (onClickHandler, hasPrev) =>
    hasPrev && (
      <button
        type="button"
        onClick={onClickHandler}
        style={{
          ...customArrowStyle,
          left: "10px", // Position for the left arrow
        }}
      >
        &#10094; {/* Left arrow symbol */}
      </button>
    );

  const renderArrowNext = (onClickHandler, hasNext) =>
    hasNext && (
      <button
        type="button"
        onClick={onClickHandler}
        style={{
          ...customArrowStyle,
          right: "10px", // Position for the right arrow
        }}
      >
        &#10095; {/* Right arrow symbol */}
      </button>
    );
  return (
    <Box sx={{ mb: 4, width: "100%" }}>
      {images.length > 0 ? (
        <Carousel
          autoPlay={true} // Starts autoplay immediately
          infiniteLoop={true} // Loops the images infinitely
          showArrows={true} // Shows navigation arrows
          showThumbs={false} // Hides thumbnail navigation
          showStatus={false} // Hides status text
          interval={3000} // Change image every 3 seconds
          transitionTime={500} // Time for transition between images (optional)
          stopOnHover={true}
          renderArrowPrev={renderArrowPrev} // Custom left arrow
          renderArrowNext={renderArrowNext} // Custom right arrow
        >
          {images.map((image, index) => (
            <Box
              key={index}
              sx={{
                height: { xs: "30vh", md: "90vh" },
                width: "100%",
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
          ))}
        </Carousel>
      ) : (
        <Box>No images available</Box> // Fallback message if images array is empty
      )}
    </Box>
  );
};

export default HeroImage;

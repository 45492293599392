import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useParams } from "react-router-dom";
import supabase from "../supabaseClient";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import EditIcon from "@mui/icons-material/Edit"; // Pencil icon

const formatDate = (dateString) =>
  new Date(dateString).toLocaleString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

const SingleStory = () => {
  const { storyId } = useParams();
  const [story, setStory] = useState(null);
  const [comments, setComments] = useState([]);
  const [category, setCategory] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [commenterName, setCommenterName] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    type: "success",
    message: "",
  });
  const [isEditing, setIsEditing] = useState(false); // Track editing state for story
  const [updatedStoryContent, setUpdatedStoryContent] = useState(""); // Track updated content for story
  const [isEditingComment, setIsEditingComment] = useState(null); // Track which comment is being edited
  const [updatedCommentContent, setUpdatedCommentContent] = useState(""); // Track updated content for comment

  // Fetch data for story, category, and comments
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: storyData, error: storyError } = await supabase
          .from("story")
          .select("*")
          .eq("storyId", storyId)
          .single();
        if (storyError) throw storyError;

        const { data: categoryData, error: categoryError } = await supabase
          .from("category")
          .select("*")
          .eq("categoryId", storyData.categoryId)
          .single();
        if (categoryError) throw categoryError;

        const { data: commentsData, error: commentsError } = await supabase
          .from("comment")
          .select("*")
          .eq("storyId", storyId)
          .order("created_at", { ascending: true });
        if (commentsError) throw commentsError;

        setStory(storyData);
        setCategory(categoryData);
        setComments(commentsData);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, [storyId]);

  // read more / read less comment
  const [isExpanded, setIsExpanded] = useState({}); // State to track expansion of each comment

  const handleToggleExpand = (commentId) => {
    setIsExpanded((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId], // Toggle the expansion for the clicked comment
    }));
  };
  // Handle adding a new comment
  const handleAddComment = async () => {
    if (!newComment.trim() || !commenterName.trim()) return;

    try {
      const { data: newCommentObj, error } = await supabase
        .from("comment")
        .insert([
          { commenter: commenterName, commentContent: newComment, storyId },
        ])
        .select()
        .single();
      if (error) throw error;

      setComments((prevComments) => [...prevComments, newCommentObj]);
      setNewComment("");
      setCommenterName("");
      setAlert({
        open: true,
        type: "success",
        message: "Kể thêm thành công nha!",
      });
    } catch (error) {
      console.error("Error adding comment:", error.message);
      setAlert({ open: true, type: "error", message: "Vui lòng thử lại" });
    }
  };

  // Handle updating the story content
  const handleUpdateStoryContent = async () => {
    if (updatedStoryContent.trim() === "") return;

    try {
      const { data: updatedStory, error } = await supabase
        .from("story")
        .update({ storyContent: updatedStoryContent })
        .eq("storyId", storyId)
        .select()
        .single();
      if (error) throw error;

      setStory(updatedStory); // Update the story state with new content
      setIsEditing(false); // Exit editing mode
      setAlert({
        open: true,
        type: "success",
        message: "Cập nhật thành công!",
      });
    } catch (error) {
      console.error("Error updating story:", error.message);
      setAlert({ open: true, type: "error", message: "Vui lòng thử lại" });
    }
  };

  // Handle canceling the edit and reverting to original content for story
  const handleCancelEdit = () => {
    setIsEditing(false);
    setUpdatedStoryContent(story.storyContent); // Revert to original content
  };

  // Handle updating the comment content
  const handleUpdateCommentContent = async (commentId) => {
    if (updatedCommentContent.trim() === "") return;

    try {
      const { data: updatedComment, error } = await supabase
        .from("comment")
        .update({ commentContent: updatedCommentContent })
        .eq("commentId", commentId)
        .select()
        .single();
      if (error) throw error;

      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.commentId === commentId ? updatedComment : comment
        )
      );
      setIsEditingComment(null); // Exit editing mode
      setAlert({
        open: true,
        type: "success",
        message: "Cập nhật thành công!",
      });
    } catch (error) {
      console.error("Error updating comment:", error.message);
      setAlert({ open: true, type: "error", message: "Vui lòng thử lại" });
    }
  };

  // Handle canceling the edit for comment
  const handleCancelEditComment = () => {
    setIsEditingComment(null); // Exit editing mode
    setUpdatedCommentContent(""); // Revert to original content
  };

  // Loading state
  if (!story || !category) return <Typography>Loading...</Typography>;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: 2,
        backgroundColor: "#f1f2f6",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          padding: 3,
          borderRadius: 2,
          boxShadow: 2,
          width: "100%",
          maxWidth: 800,
        }}
      >
        {/* Story Details */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            position: "relative",
            marginBottom: 2,
          }}
        >
          {/* Left Side: Story Name, Story Teller, Category Name, Story Date */}
          <Box>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", marginBottom: 2 }}
            >
              {story.storyName}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "text.secondary", marginBottom: 1 }}
            >
              Người kể: <strong>{story.storyTeller}</strong>
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: "text.secondary", marginBottom: 2 }}
            >
              Chuyện về: {category.categoryName}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: "text.secondary", marginBottom: 2 }}
            >
              Ngày kể: <i>{formatDate(story.created_at)}</i>
            </Typography>
          </Box>

          {/* Right Side: Pencil Edit Icon */}
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 10, // Ensure it stays on top
              backgroundColor: "white", // Make it stand out
              borderRadius: "50%", // Round button
              padding: 1,
              boxShadow: 2, // Add shadow to make it visible
            }}
            onClick={() => {
              setIsEditing(true);
              setUpdatedStoryContent(story.storyContent); // Pre-fill the editor with current content
            }}
          >
            <EditIcon />
          </IconButton>
        </Box>

        {/* Story Content Section with Pencil Icon inside */}
        <Box sx={{ position: "relative" }}>
          {/* Story Content */}
          {isEditing ? (
            <Box sx={{ marginBottom: 4 }}>
              <Editor
                apiKey="clpbjdubk5ryj807gz0v9htan0p8ie5bn8578b3tkc5jmgvz"
                value={updatedStoryContent}
                init={{
                  height: 400,
                  menubar: false,
                  plugins: ["advlist", "autolink", "lists", "link", "charmap"],
                  toolbar:
                    "fontsize | undo redo | formatselect | bold italic | bullist numlist | outdent indent | link image",
                  fontsize_formats:
                    "12pt 14pt 18pt 24pt 36pt 40pt 44pt 48pt 52pt",
                }}
                onEditorChange={(content) => setUpdatedStoryContent(content)}
                style={{ width: "100%" }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateStoryContent}
                >
                  Sửa
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancelEdit}
                >
                  Hủy
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography
              variant="body1"
              sx={{ marginBottom: 4, lineHeight: 1.8 }}
              dangerouslySetInnerHTML={{ __html: story.storyContent }}
            />
          )}
        </Box>

        <Divider sx={{ marginBottom: 4 }} />

        {/* Comments Section */}
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Chi tiết thêm ({comments.length})
        </Typography>
        <List sx={{ marginBottom: 4 }}>
          {comments.map((comment) => (
            <ListItem
              key={comment.commentId}
              sx={{
                marginBottom: 3,
                padding: 2,
                backgroundColor: "#f9f9f9",
                borderRadius: 2,
                boxShadow: 1,
                position: "relative",
              }}
            >
              <ListItemText
                primary={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "text.primary" }}>
                      {comment.commenter} kể:
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body2"
                        sx={{ fontStyle: "italic", marginRight: 1 }}
                      >
                        {formatDate(comment.created_at)}
                      </Typography>
                      <IconButton
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "50%",
                          padding: 1,
                          boxShadow: 2,
                        }}
                        onClick={() => {
                          setIsEditingComment(comment.commentId);
                          setUpdatedCommentContent(comment.commentContent); // Pre-fill the editor with current comment content
                        }}
                      >
                        <EditIcon  />
                      </IconButton>
                    </Box>
                  </Box>
                }
                secondary={
                  <Box sx={{ position: "relative" }}>
                    {isEditingComment === comment.commentId ? (
                      <Box sx={{ marginBottom: 4 }}>
                        <Editor
                          apiKey="clpbjdubk5ryj807gz0v9htan0p8ie5bn8578b3tkc5jmgvz"
                          value={updatedCommentContent}
                          init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "charmap",
                            ],
                            toolbar:
                              "fontsize | undo redo | formatselect | bold italic | bullist numlist | outdent indent | link image",
                            fontsize_formats:
                              "12pt 14pt 18pt 24pt 36pt 40pt 44pt 48pt 52pt",
                          }}
                          onEditorChange={(content) =>
                            setUpdatedCommentContent(content)
                          }
                          style={{ width: "100%" }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 2,
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleUpdateCommentContent(comment.commentId)
                            }
                          >
                            Sửa
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCancelEditComment}
                          >
                            Hủy
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Typography
                          variant="body1"
                          sx={{
                            lineHeight: 1.8,
                            color: "text.secondary",
                            "& ul": {
                              listStyleType: "disc",
                              paddingLeft: "20px",
                            },
                            "& ol": {
                              listStyleType: "decimal",
                              paddingLeft: "20px",
                            },
                            "& li": { marginBottom: "8px" },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: isExpanded[comment.commentId]
                              ? comment.commentContent
                              : `${comment.commentContent.substring(
                                  0,
                                  300
                                )}...`,
                          }}
                        />
                        {comment.commentContent.length > 300 && (
                          <Button
                            onClick={() =>
                              handleToggleExpand(comment.commentId)
                            }
                            sx={{
                              padding: 0,
                              textTransform: "none",
                              color: "primary.main",
                            }}
                          >
                            {isExpanded[comment.commentId]
                              ? "Rút gọn"
                              : "Đọc thêm"}
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>

        {/* Add Comment Section */}
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Kể thêm về chuyện này
        </Typography>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleAddComment();
          }}
        >
          <TextField
            label="Người kể"
            variant="outlined"
            fullWidth
            value={commenterName}
            onChange={(e) => setCommenterName(e.target.value)}
            required
            InputLabelProps={{ required: false }}
          />
          <Editor
            apiKey="clpbjdubk5ryj807gz0v9htan0p8ie5bn8578b3tkc5jmgvz"
            value={newComment}
            init={{
              height: 200,
              menubar: false,
              plugins: ["advlist", "autolink", "lists", "link", "charmap"],
              toolbar:
                "fontsize | undo redo | formatselect | bold italic | bullist numlist | outdent indent | link image",
              fontsize_formats: "12pt 14pt 18pt 24pt 36pt 40pt 44pt 48pt 52pt",
            }}
            onEditorChange={(content) => setNewComment(content)}
            style={{ width: "100%" }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ alignSelf: "flex-start", marginTop: 2 }}
          >
            kể thêm <ArrowCircleRightOutlinedIcon sx={{ ml: 1 }} />
          </Button>
        </Box>
      </Box>

      {/* Snackbar for alerts */}
      <Snackbar
        open={alert.open}
        autoHideDuration={5000}
        onClose={() => setAlert({ ...alert, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SingleStory;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import HeroImage from "../components/HeroImage";
import SectionDivider from "../components/SectionDivider";
import supabase from "../supabaseClient";
import birthdayWgrandpa from "../asset/images/birthdayWgrandpa.jpg";
import Story from "../asset/images/Story.jpg";

const Stories = () => {
  // States
  const [viewMore, setViewMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [stories, setStories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const imageData = [birthdayWgrandpa, Story];

  // Fetch stories and categories
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          { data: storiesData, error: storiesError },
          { data: categoriesData, error: categoriesError },
        ] = await Promise.all([
          supabase
            .from("story")
            .select("*")
            .order("created_at", { ascending: false }),
          supabase
            .from("category")
            .select("categoryId, categoryName")
            .order("categoryId", { ascending: true }),
        ]);

        if (storiesError) throw storiesError;
        if (categoriesError) throw categoriesError;

        // Define the custom order for categories
        const categoryOrder = [1, 10, 2, 3, 4, 5, 6, 7, 8, 9];

        // Sort the categories based on the custom order
        const sortedCategories = categoriesData.sort(
          (a, b) =>
            categoryOrder.indexOf(a.categoryId) -
            categoryOrder.indexOf(b.categoryId)
        );

        setStories(storiesData || []);
        setCategories(sortedCategories || []);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handlers
  const handleSearch = (e) => setSearchQuery(e.target.value);
  const handleCategoryChange = (e) => setSelectedCategory(e.target.value);
  const handleViewMore = () => setViewMore(!viewMore);

  // Filtered stories
  const filteredStories = stories.filter(({ storyName, categoryId }) => {
    const matchesSearch = storyName
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory
      ? categoryId === selectedCategory
      : true;
    return matchesSearch && matchesCategory;
  });

  // Get category name by ID
  const getCategoryNameById = (categoryId) => {
    const category = categories.find((cat) => cat.categoryId === categoryId);
    return category ? category.categoryName : "Unknown Category";
  };

  return (
    <Box>
      {/* Hero Section */}
      <HeroImage images={imageData} />
      <SectionDivider text="Đọc những câu chuyện kể rồi" />

      {/* Subtitle */}
      <Typography
        variant="h6"
        sx={{ m: 2, textAlign: "center", fontStyle: "italic" }}
      >
        Hãy lấy một tách trà hoặc một ly cà phê, rồi ngồi yên tĩnh, và đọc lại
        những câu chuyện đã được kể sau đây
      </Typography>

      {/* Search and Filter */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          p: 2,
          width: "100%",
        }}
      >
        <TextField
          label="Tìm chuyện"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          sx={{ width: "22.5%", height: "56px" }}
        />
        <FormControl sx={{ width: "22.5%", height: "56px" }}>
          <InputLabel>Phân loại</InputLabel>
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            label="Phân loại"
            sx={{ width: "100%", height: "100%" }}
          >
            <MenuItem value="">Tất cả</MenuItem>
            {categories.map(({ categoryId, categoryName }) => (
              <MenuItem key={categoryId} value={categoryId}>
                {categoryName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Loading or Stories List */}
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Stories */}
          <Box sx={{ p: 4, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2} sx={{ maxWidth: "1200px" }}>
              {filteredStories
                .slice(0, viewMore ? filteredStories.length : 4)
                .map(({ storyId, storyName, storyTeller, categoryId }) => (
                  <Grid item xs={12} sm={6} key={storyId}>
                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #ddd",
                        p: 2,
                      }}
                    >
                      <ListItemText
                        primary={
                          <>
                            <Button
                              component={Link}
                              to={`/stories/${storyId}`}
                              sx={{
                                textDecoration: "none",
                                color: "primary.main",
                                fontSize: "1.5rem",
                                textTransform: "uppercase",
                                lineHeight: 1.5,
                                mb: "0.5rem",
                              }}
                            >
                              {storyName}
                            </Button>
                            <Typography
                              sx={{
                                fontSize: "1.2rem",
                                fontStyle: "italic",
                                lineHeight: 1.5,
                                mb: "1rem",
                              }}
                            >
                              Người kể: {storyTeller}
                            </Typography>
                          </>
                        }
                        secondary={
                          <Typography
                            sx={{
                              fontSize: "1.1rem",
                              color: "text.secondary",
                              lineHeight: 1.8,
                              fontStyle: "italic",
                            }}
                          >
                            Thư mục chuyện: {getCategoryNameById(categoryId)}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Grid>
                ))}
            </Grid>
          </Box>

          {/* View More Button */}
          <Box sx={{ textAlign: "center", m: 2 }}>
            <Button variant="contained" onClick={handleViewMore}>
              {viewMore ? "rút gọn" : "đọc thêm"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Stories;

import React, { useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import SingleStory from "./pages/SingleStory";
import Families from "./pages/Families";
import Stories from "./pages/Stories";
import Libraries from "./pages/Libraries";
import Demo from "./pages/demo";
import Login from "./pages/Login";
import { AuthProvider, useAuth } from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import NotFound from "./pages/NotFound";
import AppWrapper from "./hooks/AppWrapper";
import Audio from "../src/asset/audio.mp3";
import { IconButton, Box } from "@mui/material";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import PauseIcon from "@mui/icons-material/Pause";

const AppContent = () => {
  const location = useLocation();
  const { authenticated } = useAuth();

  const isLoginPage = location.pathname === "/login";

  return (
    <>
      {!isLoginPage && authenticated && <Navbar />}
      <Routes>
        <Route path="/" element={<PrivateRoute element={<Home />} />} />
        <Route path="/home" element={<Navigate to="/" />} />
        <Route
          path="/families"
          element={<PrivateRoute element={<Families />} />}
        />
        <Route
          path="/family/:familyId"
          element={<PrivateRoute element={<Families />} />}
        />
        <Route
          path="/stories"
          element={<PrivateRoute element={<Stories />} />}
        />
        <Route
          path="/libraries"
          element={<PrivateRoute element={<Libraries />} />}
        />
        <Route
          path="/stories/:storyId"
          element={<PrivateRoute element={<SingleStory />} />}
        />
        <Route path="/demo" element={<Demo />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!isLoginPage && authenticated && <Footer />}
    </>
  );
};

const App = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play().catch((error) => {
          console.error("Audio playback failed:", error);
        });
        setIsPlaying(true);
      }
    }
  };

  return (
    <Router>
      <AuthProvider>
        <AppWrapper>
          <AppContent />
          <Box
            sx={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: 1000,
            }}
          >
            <IconButton
              onClick={toggleAudio}
              sx={{
                backgroundColor: isPlaying ? "#4caf50" : "#f44336", // Green if playing, red if paused
                color: "white",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  backgroundColor: isPlaying ? "#45a049" : "#e53935", // Lighter hover color
                },
              }}
            >
              {isPlaying ? <PauseIcon fontSize="large" /> : <MusicNoteIcon fontSize="large" />}
            </IconButton>
          </Box>
          <audio ref={audioRef} src={Audio} loop />
        </AppWrapper>
      </AuthProvider>
    </Router>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import supabase from "../supabaseClient";

const Libraries = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [categories, setCategories] = useState([]);
  const [galleries, setGalleries] = useState({});
  const [expanded, setExpanded] = useState(1); // Default open accordion for categoryId = 1
  const [viewMoreLimit, setViewMoreLimit] = useState({}); // Limit for each category

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch categories and galleries concurrently
        const [categoryRes, galleryRes] = await Promise.all([
          supabase.from("category").select("categoryId, categoryName"),
          supabase
            .from("gallery")
            .select("*")
            .order("categoryId", { ascending: true }),
        ]);

        if (categoryRes.error || galleryRes.error) {
          throw new Error(
            categoryRes.error?.message || galleryRes.error?.message
          );
        }

        // Reorder categories based on specified order
        const categoryOrder = [1, 10, 2, 3, 4, 5, 6, 7, 8, 9];
        const orderedCategories = categoryRes.data.sort(
          (a, b) =>
            categoryOrder.indexOf(a.categoryId) -
            categoryOrder.indexOf(b.categoryId)
        );

        setCategories(orderedCategories);

        // Group galleries by categoryId
        const galleryMap = galleryRes.data.reduce((acc, gallery) => {
          acc[gallery.categoryId] = acc[gallery.categoryId] || [];
          acc[gallery.categoryId].push(gallery);
          return acc;
        }, {});

        setGalleries(galleryMap);

        // Set initial view limit for each category (default 4)
        const initialLimits = orderedCategories.reduce((acc, category) => {
          acc[category.categoryId] = 4; // Default to 4 items per category
          return acc;
        }, {});
        setViewMoreLimit(initialLimits);
      } catch (error) {
        console.error("Error fetching data: ", error.message);
      }
    };

    fetchData();
  }, []);

  // Handle input changes for search
  const handleSearchChange = (event) => setSearchQuery(event.target.value);

  // Filter galleries based on search query
  const filterGalleries = (categoryId) => {
    const categoryGalleries = galleries[categoryId] || [];
    return categoryGalleries.filter((gallery) =>
      gallery.galleryName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  // Handle accordion expand/collapse
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Fetch more galleries when "View More" is clicked
  const handleViewMore = (categoryId) => {
    setViewMoreLimit((prevLimits) => ({
      ...prevLimits,
      [categoryId]: Infinity, // Set to Infinity to remove the limit
    }));
  };

  // Fetch less galleries when "View Less" is clicked
  const handleViewLess = (categoryId) => {
    setViewMoreLimit((prevLimits) => ({
      ...prevLimits,
      [categoryId]: 4, // Reset limit back to 4
    }));
  };

  return (
    <Box>
      {/* Title */}
      <Typography variant="h5" sx={{ m: 2, textAlign: "center" }}>
        Nơi lưu giữ hình ảnh của đại gia đình qua từng năm tháng và qua từng sự
        kiện, biến cố buồn vui
      </Typography>

      {/* Search Bar */}
      <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
        <TextField
          label="Tìm kỉ niệm"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ width: "50%", height: "56px" }}
        />
      </Box>

      {/* Accordion for Categories */}
      <Box sx={{ padding: 2 }}>
        {categories
          .filter((category) => {
            const hasMatchingGalleries =
              filterGalleries(category.categoryId).length > 0;
            return searchQuery === "" || hasMatchingGalleries; // Show all if no search query, otherwise filter
          })
          .map((category) => (
            <Accordion
              key={category.categoryId}
              expanded={expanded === category.categoryId}
              onChange={handleAccordionChange(category.categoryId)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${category.categoryId}-content`}
                id={`panel-${category.categoryId}-header`}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {category.categoryName}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {filterGalleries(category.categoryId).length === 0 ? (
                  // Display this message only for empty categories
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ textAlign: "center", mt: 2 }}
                  >
                    {searchQuery
                      ? "Không tìm thấy kỉ niệm nào phù hợp với từ khóa."
                      : "Chưa có hình ảnh hoặc sự kiện nào rồi :( hỏi Híu để thêm dzô nha."}
                  </Typography>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      {filterGalleries(category.categoryId)
                        .slice(0, viewMoreLimit[category.categoryId] || 4) // Apply limit
                        .map((gallery) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            key={gallery.galleryId}
                          >
                            <Card
                              sx={{
                                cursor: "pointer",
                                transition: "transform 0.3s ease-in-out",
                                "&:hover": { transform: "scale(1.05)" },
                              }}
                              onClick={() =>
                                window.open(gallery.galleryLink, "_blank")
                              }
                            >
                              <CardMedia
                                component="img"
                                alt={gallery.galleryName}
                                image={gallery.galleryThumbnail}
                                sx={{
                                  height: 300,
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                              />
                              <CardContent>
                                <Typography variant="h6" component="div">
                                  {gallery.galleryName}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>

                    {/* View More / View Less Button */}
                    {filterGalleries(category.categoryId).length > 4 && (
                      <Box sx={{ textAlign: "center", mt: 2 }}>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            viewMoreLimit[category.categoryId] === Infinity
                              ? handleViewLess(category.categoryId)
                              : handleViewMore(category.categoryId)
                          }
                        >
                          {viewMoreLimit[category.categoryId] === Infinity
                            ? "rút gọn"
                            : "Xem thêm"}
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
    </Box>
  );
};

export default Libraries;
